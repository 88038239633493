.color__two {
  font-size: 18px;
  padding: 10px 34px;
  border-radius: 7px;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  position: relative;
  border: 2px solid #f1592b;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  z-index: 2;
  font-weight: 500;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.Standard__sss img {
    max-width: 182px;
	object-fit: cover;
}
.color__two:hover {
  color: #f1592b;
  border-color: #f1592b;
  box-shadow: none;
  background-color: white;
}
.color__two:hover:after {
  top: 0;
  bottom: auto;
  height: 0;
}
.color__two:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  content: "";
  color: #000 !important;
  background: #f1592b;
  -webkit-transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -o-transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.tabless__listsss .MuiTable-root th {
  color: #000000;
  background-color: #fff;
  border: 1px solid #eaedf1;
}
.tabless__listsss  .MuiTableCell-root{
  font-size: 18px;
  border: 1px solid #eaedf1;
  max-width: 347px;
}
.tabless__listsss .MuiTableRow-root:nth-of-type(odd) {
  background-color: rgb(255 255 255 / 4%);
}
.Impressive__section {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 60px 0;
  background: linear-gradient( to right, rgb(44 44 45) 0%, rgb(66 68 75) 100%)!important;
  z-index: 0;
}
.Impressive__innerpage{display: flex;flex-direction: row;}
.Impressive__cover {
  max-height: 437px;
  height: 437px;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  border-radius: 17px;
  margin-top: 17px;
}
.catsssimprsss{
  font-size: 18px;
  line-height: 37px;
  font-family: var(--font-family);
  color: #ffeb3b;
}
.Impressive__innerpagelist{display: flex; flex-wrap: wrap;justify-content: space-between;}
.Impressive__innerpagetwo img {
  max-height: 177px;
  height: 177px;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  border-radius: 7px;
  margin: 0 auto;
  display: block;
}
.Impressive__innerpagetwo h1 {
  font-size: 17px;
  color: white;
  margin-top: 10px;
  line-height: 30px;
  text-align: center;
  max-height: 64px;
  overflow: hidden;
}
.tabless__listsss {
  margin-bottom: 60px;
}
.Impressive__innerpagetwo {
  width: 50%;
  margin-bottom: 10px;
}
.text__inner__four2 p, .text__inner__four2 span{color: white!important;}
.Impressive__innerpage .text__inner h1 {
  font-family: var(--font-family);
  font-size: 30px;
  line-height: 47px;
  font-weight: 600;
  margin-bottom: 0px;
  color:white;
}
.Impressive__headingsss {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 37px;
  text-align: center;
  color: #f1592b;
  text-shadow: 0px 4px 7px #000000;
}
.Impressive__innerpage .text__inner p {
  font-size: 18px;
  line-height: 37px;
  font-family: var(--font-family);
  color: #ffeb3b;
}
img.seller__img {
  height: 107px;
  width: 107px;
  max-width: 117px;
  background: #f5f6f9;
  border-radius: 50%;
  object-fit: cover;
  object-position: center top;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
}
.Impressive__fullsection {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 0;
  background-color: #eff8ff;
  overflow: hidden;
}
.fullsectionsss{
  display: flex;
}
.fullsectionsss__reverse{flex-direction: row-reverse; transition: all .30s; -webkit-transition: all .30s;}
.RightArrow {
  position: absolute;
  right: 7px;
  z-index: 999;
  top: 38%;
  background-color: white;
  border-radius: 100%;
  width: 47px;
  height: 47px;
  cursor: pointer;
  display: none;
}
.RightArrow svg{
  color: #f1592b;
  font-size: 36px;
  vertical-align: middle;
  text-align: center;
  margin-top: 6px;
  margin-left: 6px;
}
.RightArrow:hover {background-color: #f1592b;}
.RightArrow:hover svg{color: white;}
.Impressive__fullsection:hover .RightArrow{
  display: block;
}
.Impressive__fullsection:hover .LeftArrow{
  display: block;
}
.LeftArrow{
  position: absolute;
  left: 7px;
  z-index: 999;
  top: 38%;
  background-color: white;
  border-radius: 100%;
  width: 47px;
  height: 47px;
  cursor: pointer;
  display: none;
}
.LeftArrow:hover {background-color: #f1592b;}
.LeftArrow:hover svg{color: white;}
.LeftArrow svg{
  color: #f1592b;
  font-size: 36px;
  vertical-align: middle;
  text-align: center;
  margin-top: 6px;
  margin-left: 6px;
}
.fullsectionsss .itemsss {
  position: relative;
  z-index: 0;
  max-width: 244px;
  overflow: hidden;
  cursor: pointer;
}
.fullsectionsss .itemsss:hover {
  cursor: url(https://www.intlbooks.com/api/uploads/cursor.png), auto;
}
.fullsectionsss .itemsss:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, #37383d 90%);
}
.fullsectionsss .itemsss:hover:before {
  height: 100%;
  -webkit-background-image: linear-gradient(rgb(0 0 0 / 52%) 13%, #00000096 78%);
  background-image: linear-gradient(rgb(0 0 0 / 52%) 13%, #00000096 78%);
  top: 0;
}
.fullsectionsss .itemsss img {
  height: 367px;
  object-fit: cover;
  width: 244px;
  transition: all .6s;
  transform-style: preserve-3d;
  overflow: hidden;
}
.fullsectionsss .itemsss:hover img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
  z-index: 0;
}
.itemsss:hover .itemsss2 {
  transform: translate(103%, 47%) scale(1);
  text-decoration: none;
  transition: 400ms;
}
.itemsss .itemsss2:hover {
  color: #f1592b;
  font-size: 67px!important;
}
.itemsss:hover .itemsss__two {
  bottom: 37px;
  transition: 400ms;
}
.itemsss:hover .itemsss__two h4 {
  display: block;
  transition: 400ms;
  color: #f1592b;
}
.itemsss2 {
  position: absolute;
  top: 27%;
  z-index: 100000;
  color: #ffffff;
  left: 10%;
  font-size: 57px!important;
  -webkit-transform: translate(-50%, -40%) scale(0);
  transform: translate(-50%, -40%) scale(0);
  transition: 400ms;
}
.itemsss__two {
  position: absolute;
  bottom: 0;
  z-index: 7;
  transition: 400ms;
}
.itemsss__two h4 {
  color: white;
  font-size: 20px;
  text-align: center;
  line-height: 27px;
  font-family: var(--font-family);
  padding: 0 10px 0 10px;
  display: none;
}
.itemsss__two h2 {
  color: white;
  font-size: 18px;
  text-align: center;
  line-height: 27px;
  font-family: var(--font-family);
  padding: 0 10px 0 10px;
}
.text__inner__two {
  display: flex;
  margin-bottom: 27px;
  margin-top: 27px;
  border-top: blue;
}
.text__inner__two h3 {
  font-size: 27px;
  font-weight: 500;
  font-family: var(--font-family);
  color: #ffffff;
  margin-bottom: 0;
  text-shadow: -1px 2px 6px #000000;
}
.Impressive__innerpage .seller__authsss {
  color: rgb(190 191 190)!important;
}
.Impressive__innerpage .MuiButton-containedPrimary {
  background-color: #f71d36;
  background-image: linear-gradient(to bottom right, #f1bf64 0%, #f71d36 100%) !important;
}
.text__inner__three {
  display: flex;
  flex-direction: column;
  margin-left: 27px;
  justify-content: center;
}
.text__inner__three p{margin-bottom: 0;font-size: 16px!important;}
.text__inner__four{display: flex;}
.text__inner__four2 {
  margin-left: 27px;
}
.BestDeals__section {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 70px 0px 70px 0px;
  overflow: hidden;
  z-index: 0;
}
.BestDeals__section:after {
  background-color: #f6fcff;
  border-radius: 0 0 68% 68%;
  position: absolute;
  content: "";
  width: 100%;
  height: 98%;
  z-index: -1;
  top: 0;
}
.BestDeals__headingsss {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: left;
  color: #000000;
  text-shadow: 0px 1px 3px #f8997f;
  position: absolute;
  top: -67px;
  left: 0;
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
  padding-bottom: 7px;
}
.BestDeals__headingsss:before {
  content: "";
  width: 67px;
  height: 3px;
  background: #fe4310;
  bottom: -2px;
  position: absolute;
}
.BestDeals__innerpagelist {
  display: flex;
  justify-content: space-around;
  position: relative;
}
.BestDeals__qqq:first-child img {
  max-height: 470px;
  height: 470px;
}
.BestDeals__innerpagesss{position: relative;}
.BestDeals__innerpagelist img {
  max-height: 267px;
  height: 267px;
  margin: 0 auto;
  display: block;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  border-radius: 7px;
}
.BestDeals__firsss {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.BestDeals__innerpage{
  padding: 17px;
}
.BestDeals__innerpage p {
  font-size: 17px;
  text-align: center;
  color: #ff400c;
  margin-bottom: 7px;
}
.BestDeals__innerpage h1 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  text-align: center;
  color: #064532;
  margin-top: 30px;
  max-height: 82px;
  overflow: hidden;
}
.bestdelsss__buttons {
  position: absolute!important;
  bottom: 22%;
  width: 207px;
  left: 30%;
}
.BestDeals__innerpagesss {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.Featured__section {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 17px 0px 70px 0px;
  overflow: hidden;
}
.Featured__headingsss {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 27px;
  text-align: center;
  color: #000000;
  text-shadow: 0px 2px 2px #a32806;
}
.Featured__innerpagelist {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Featured__innerpage {
  display: flex;
  box-shadow: 1px 30px 60px 8px rgb(70 72 228 / 7%);
  padding: 27px;
  background-color: #ffe7e0;
  background-image: linear-gradient(to bottom right, #ffffff 0%, #ffefea 100%) !important;
  border-radius: 7px;
}
.Featured__innerpage img {
  max-height: 217px;
  height: 217px;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  border-radius: 7px;
}
.Featured__innerpage h1 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #792405;
  margin-bottom: 10px;
  max-height: 56px;
  overflow: hidden;
}
.feaaa__desccc, .feaaa__desccc p, .feaaa__desccc p span, .feaaa__desccc p strong{
  font-size: 15px!important;
  color: #575957!important;
  line-height: 27px;
  font-family: var(--font-family)!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.Featured__innerpage h6 {
  font-size: 24px;
  color: #ff400c;
  font-weight: 700;
  margin: 17px 0px 17px 0px;
}
.containedPrimary2 {
  color: #fe3e0a!important;
  background-color: #3f51b5!important;
  background: linear-gradient(to bottom right, #ffffff 0%, #f6e7eb 100%) !important;
  box-shadow: 0px 3px 1px -2px rgb(253 245 243), 0px 2px 2px 0px rgb(252 237 236), 0px 1px 5px 0px rgb(253 250 251) !important;
}
.containedPrimary2:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)!important;
}
.Featured__innerauthorsss {
  font-size: 14px;
  color: #555755;
  margin-bottom: 7px;
}
.Featured__innertext {
  margin-left: 17px;
}
.Category__section{
  position: relative;
}
.Category__sec2{
  overflow: hidden;
  position: relative;
}
.Category__sec2 a {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  cursor: pointer;
}
.Category__sec2 span {
  background-color: white;
  padding: 14px 27px 14px 27px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.Category__sec2 img{
  width: 100%;
}
.Category__sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Category__sec3 {
  width: 48.6%;
  margin-bottom: 17px;
}
.Category__sec img {
  width: 100%;
}
.Category__sec3{
  overflow: hidden;
  position: relative;
}
.Category__sec3 a {
  position: absolute;
  left: 27px;
  bottom: 43px;
  cursor: pointer;
}
.Category__sec3 span {
  background-color: white;
  padding: 14px 27px 14px 27px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.Category__sec2:hover{
  animation-name: elementor-animation-wobble-horizontal;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.Category__sec3:hover{
  animation-name: elementor-animation-wobble-horizontal;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes elementor-animation-wobble-horizontal {
	16.65% {
		transform: translateX(8px)
	}
	33.3% {
		transform: translateX(-6px)
	}
	49.95% {
		transform: translateX(4px)
	}
	66.6% {
		transform: translateX(-2px)
	}
	83.25% {
		transform: translateX(1px)
	}
	100% {
		transform: translateX(0)
	}
}
.Category__sec2 span:hover {
  background-color: #f1592b;
  color: white;
}
.Category__sec3 span:hover {
  background-color: #f1592b;
  color: white;
}
.Standard__section {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 60px 0 0;
  overflow: hidden;
}
.Standard__headingsss {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: left;
  color: #000000;
  text-shadow: 0px 1px 3px #f8997f;
  position: absolute;
  top: -67px;
  left: 0;
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
  padding-bottom: 7px;
}
.Standard__headingsss:before {
  content: "";
  width: 67px;
  height: 3px;
  background: #fe4310;
  bottom: -2px;
  position: absolute;
}
.Standard__desc, .Standard__desc p, .Standard__desc p span, .Standard__desc p strong{
  font-size: 17px!important;
  color: #575957!important;
  line-height: 37px;
  font-family: var(--font-family)!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.error {
  color: rgb(255, 0, 0);
}
.Standard__innerpagelist {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Standard__innerpagesss {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}
.Standard__innerpage {
  padding: 17px;
}
.Standard__innerpagelist .Standard__list2 img {
  max-height: 470px;
  height: 470px;
  max-width: 367px;
  object-fit: cover;
  object-position: center top;
}
.Standard__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
}
.Standard__innerpagelist img {
  max-height: 237px;
  height: 237px;
  margin: 0 auto;
  display: block;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  border-radius: 7px;
}
.Standard__innerpage p {
  font-size: 16px;
  text-align: center;
  margin-bottom: 7px;
}
.Standard__authsss{
  margin-top: 30px;
  color: #fe410e;
}
.Standard__innerpage h1 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  text-align: center;
  color: #064532;
  max-height: 83px;
  overflow: hidden;
}

.books__section {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px 0px 70px 0px;
  overflow: hidden;
}
.books__section2 {
  display: flex;
  flex-direction: column;
  padding: 17px;
}
.books__section3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}
.books__section2 img {
  margin: 0 auto 17px;
  border-radius: 7px;
  background: #f5f6f9;
  object-fit: cover;
  object-position: center top;
  width: auto;
  margin-right: 17px;
  height: 297px;
  max-width: 100%;
}
.books__section2 h1 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  text-align: center;
  color: #064532;
}
.books__section2 h6 {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #f1592b;
}
.books__section2 p {
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
  color: #555755;
  margin-bottom: 7px;
}
.books__section4 {
  padding: 17px;
  border-radius: 3px;
  font-size: 15px;
  margin-bottom: 40px;
  background: #fff;
}
h1.sub__headingsss2 {
  font-size: 34px;
  line-height: 1.1;
  font-family: var(--font-family);
  font-weight: bold;
  color: #f1592b;
  margin: 0;
  position: relative;
  display: inline-block;
  top: 5px;
  padding-bottom: 15px;
}
.title__bestseller{
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 37px;
}
h1.sub__headingsss2:before {
  content: "";
  width: 50px;
  height: 3px;
  background: #064532;
  bottom: 3px;
  position: absolute;
}
h1.sub__headingsss {
  padding: 10px 0;
  margin: 0 0 13px;
  text-transform: capitalize;
  font-size: 20px;
  color: #000;
  background: transparent;
  font-weight: 600;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e1e1e1;
  line-height: 20px;
}
.sub__headingsss:before {
  content: "";
  width: 50px;
  height: 3px;
  background: #064532;
  bottom: -1px;
  position: absolute;
}
.books__section .book__desc p {
  font-size: 16px;
  margin-top: 0;
  text-align: left;
  color: #5a5b5a;
  margin-bottom: 7px;
}
.books__section__preview {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px 0px 70px 0px;
  overflow: hidden;
}
.book__desc__preview, .book__desc__preview p, .book__desc__preview p span, .book__desc__preview p span strong, .book__desc__preview p strong {
  font-size: 17px!important;
  color: #575957!important;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--font-family)!important;
}

.books__section__preview img {
  margin: 0 auto 37px;
  background-color: #ffffff;
  border-radius: 7px;
  max-height: 437px;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  max-width: 337px;
  display: flex;
}
.book__preview__title {
  text-align: left;
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 24px;
}
.book__prewsss{display: flex;align-items: center;}
.books__section4 ul {
  position: relative;
  padding: 0;
  margin: 0;
}
.books__section4 li {
  padding: 10px 20px !important;
  border-bottom: 1px dashed #e9e9e9;
  list-style: none;
  color: #5b5d5c;
  position: relative;
  font-size: 17px;
}
.books__section4 li:before {
  position: absolute;
  content: "";
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: hsl(0deg 0% 83% / 95%);
  top: 18px;
}
.breadcrumbs{ 
  background: #f0f3f8;
  margin: 0 0 50px;
  font-size: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.breadcrumb .delimiter {
  color: #767a79;
}
.breadcrumb .delimiter svg{
  vertical-align: middle;
}
.breadcrumb {
  font-size: 17px;
}
.breadcrumb a {
  color: #5e5f5f;
  position: relative;
  font-size: 17px;
}
h1.sub__headingsss2s {
  font-size: 18px;
  line-height: 1.1;
  font-family: var(--font-family);
  font-weight: bold;
  color: #000000;
  margin: 0;
  position: relative;
  display: inline-block;
  top: 5px;
  padding-bottom: 17px;
}
h1.sub__headingsss2s:before {
  content: "";
  width: 50px;
  height: 3px;
  background: #064532;
  bottom: 3px;
  position: absolute;
}
.Authors__img {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #f0f3f8;
  margin-bottom: 27px;
  padding: 17px;
  background: linear-gradient(to right, #f0f3f8 17%, #ffffff 60%) !important;
}
.Authors__img a{
  display: flex;
}
.Authors__imgcontent {
  display: flex;
  flex-direction: column;
  margin-left: 27px;
  align-items: flex-start;
  justify-content: center;
}
.Authors__imgcontent p{
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 7px;
}
.Authors__img img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: center top;
  margin-bottom: 0;
}
.author__types {
  border: 1px solid #f1592b;
  border-radius: 4px;
  color: #f1592b;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  padding: 2px 10px 2px;
  font-weight: 600;
}
.bookpres__linsss {padding: 0;}
.bookpres__catsss {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  position: relative;
}
.bookpres__linsss li {
  font-size: 17px;
  cursor: pointer;
  color: #0676cf;
  margin-bottom: 10px;
}
.bookpres__catsss li {
  font-size: 17px;
  cursor: pointer;
  color: #474b47;
  margin: 0 10px 0 0px;
}
.bookpres__catsss li:after {
 content: ",";
}
.bookpres__catsss li:first-child:after, .bookpres__catsss li:last-child:after {
  content: "";
 }
.bookpres__linsss b{
  color: #f1592b;
}
h6.bookpres__pricsss {
  font-size: 27px;
  color: #f44336;
  margin-bottom: 17px;
  font-weight: 700;
}
.colsss{color: #0676cf;font-size: 18px;}
.authors__section__preview {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 70px 0px 70px 0px;
    overflow: hidden;
    background-color: #f7fcff;
}
.authors__section__list {
  background-color: #ffffff;
  box-shadow: 10px 20px 137px 0px #e2eefa;
  border-radius: 20px;
  padding: 22px 22px 22px 22px;
  margin-right: 17px;
  margin-left: 17px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  margin-bottom: 37px;
}
.authors__section__preview button{
  margin-top: 17px;
}
.authors__section__list2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.authors__section__list2 img {
  margin: 0 auto 27px;
  display: block;
  height: 177px;
  width: 177px;
  max-width: unset;
  border-radius: 7px;
  object-fit: cover;
  box-shadow: -4px 24px 39px -17px rgb(204 232 255);
}
.authors__section__list2 h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}
.authors__section__list2 .author__types {
  border: 0px solid #f1592b;
  margin-bottom: 17px;
}
.booklistsss img {
  height: 277px;
  max-width: 277px;
  margin: 17px auto 27px 37px;
  display: block;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  border-radius: 7px;
  object-fit: contain;
}
.booklistssspublishsss {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.profileasss{margin-top: 17px;}
.profileasss h4 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
}
.author__desc__preview{margin-bottom: 37px;}
.ausss__section__preview h1.sub__headingsss2s {
  font-size: 23px;
}
.mr_37{margin-top: 37px;}
.vidsss{ 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.publisherssss{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.publisherssss img {
  height: 177px;
  max-width: 277px;
  margin: 17px auto 27px 37px;
  display: block;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  border-radius: 7px;
  object-fit: contain;
}
.booklistssspublishsss img {
  height: 177px;
  max-width: 277px;
  margin: 17px auto 27px 37px;
  display: block;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  border-radius: 7px;
  object-fit: contain;
}
.booklistsss {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.booklistsss__two h2 {
  font-size: 20px;
  text-align: center;
  padding: 0 15px 0 15px;
}
.author__sss {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.authors__section__list3 {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  align-content: center;
}
.colsss {
  cursor: pointer;
}
.authors__section__preview .mr__27{margin: 0 auto 27px;float: none;}
.mr__top__17{margin-top: 17px}
.authors__breadcrumbs{margin: 0;background: #ebf5fc;}
.author__innersss {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: space-around;
}
.Audio__section {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 60px 0px 27px 0px;
  overflow: hidden;
}
.Audio__innerpagesss {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: row;
}
.Audio__innerpagesss img{
  max-height: 227px;
  overflow: hidden;
  margin: 0 auto;
  display: block;
  box-shadow: -3px 1px 6px #5f5d5d;
  border-radius: 10px;
}
.Audio__innerpage {
  padding: 25px 30px 30px 30px;
}
.Audio__innerpage0 {background-color: #F6BBB5;}
.Audio__innerpage1 {background-color: #D3EBED;}
.Audio__innerpage2 {background-color: #FFF2DF;}
.Audio__innerpage3 {background-color: #D7ECFF;}
.Audio__innerpage p {
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
  color: #555755;
  margin-bottom: 7px;
}
.Audio__innerpage h1 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  text-align: center;
  color: #064532;
}
.Audio__headingsss {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 27px;
  text-align: center;
  color: #000000;
  text-shadow: 0px 2px 2px #a32806;
}
.Video__section {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 60px 0px 47px 0px;
  overflow: hidden;
  background: linear-gradient( to right, rgb(34 36 44) 0%, rgb(89 88 88) 100%)!important;
  z-index: 0;
  margin: 60px 0px 37px 0px;
}
.Video__section:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -2;
  background-image: url(https://intlbooks.com/api/uploads/pattern5.png);
  background-position: top left, bottom right;
  background-repeat: no-repeat;
  background-size: 100%;
}
.Video__headingsss {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 2px 2px #000000;
}
.Video__sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Video__sec2{
  display: flex;
}
.Video__sec.Video__sec3 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
}
.Video__innerpage2{
  position: relative;
  width: 100%;
  padding: 37px;
}
.Video__innerpage2 iframe {
  width: 100%;
  height: 437px;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0);
  border-radius: 17px;
  border: 7px solid white;
}
.Video__sec .Video__innerpage2 h1 {
  font-size: 23px;
  margin-top: 37px;
  text-align: center;
}
.Video__innerpage{
  position: relative;
  width: 100%;
}
.Video__innerpage iframe {
  width: 97%;
  height: 220px;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0);
  border: 4px solid #ffffff;
  outline: 1px solid #5b5d69;
  outline-offset: 37px;
  border-radius: 17px;
}
.Video__sec h1 {
  font-size: 16px;
  line-height: 37px;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
}
.Featuredqqq{
  display: flex;
}
.publisher__section {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 27px 0px 60px 0px;
  overflow: hidden;
}
.publisher__headingsss {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 27px;
  text-align:center;
  color: #000000;
  text-shadow: 0px 2px 2px #a32806;
}
.publisher__innerpagesss img {
  height: 177px;
  max-width: 247px;
  overflow: hidden;
  margin: 0 auto 17px;
  display: block;
  border-radius: 10px;
  object-fit: contain;
}
.author__desc__preview, .author__desc__preview p, .author__desc__preview p span, .author__desc__preview p strong {
  font-size: 18px!important;
  color: #575957!important;
  line-height: 37px;
  font-family: var(--font-family)!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.auss__mr__37{margin-bottom: 37px;}
.publisher__innerpagesss {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}
.publisher__innerpage {
  padding: 30px 30px 30px 30px;
  display: flex;
  box-shadow: 1px 30px 60px 8px rgb(70 72 228 / 7%);
  background-color: #ffffff;
  border-radius: 7px;
}
.publisher__innerpage h1 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  text-align: center;
  color: #064532;
}
.publisher__desc, .publisher__desc p, .publisher__desc p span, .publisher__desc p strong, .publisher__desc p span strong {
  font-size: 15px!important;
  color: #575957!important;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--font-family)!important;
}
.Standard__innerpageqqq {
  padding: 17px;
  background-color: #ffe7e0;
  background-image: linear-gradient(to top right, #e7f1fb 0%, #ffffff 100%) !important;
  border-radius: 17px;
}
div#Footer {
  border-style: solid;
  border-width: 1px 0 1px 0;
  border-color: #EDEDED;
  transition: background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
  padding: 27px 15px 7px 15px;
  background: linear-gradient( to top, rgb(255 235 215) 3%, rgb(255 255 255) 57%)!important;
}
.Toastify__toast-body {
  font-size: 18px;
  color: black;
  line-height: 30px;
}
.Search__menus {
  position: absolute;
  width: 45%;
  right: 87px;
  top: 17px;
  z-index: 999;
}
.Search__menus input {
  width: 100%;
  padding: 13px;
  border: 1px solid #f1592b;
  outline: none;
  border-radius: 5px;
  display: block;
  margin-bottom: .5rem;
  color: black;
}
.searchmenu__listing__item {
  text-align: left;
  position: absolute;
  top: 100%;
  display: block;
  margin: 7px 0 12px;
  background-color: #fff;
  color: #000000;
  border: 1px solid #dcdedf;
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  width: 100%;
  padding: 17px;
  z-index: 999;
}
.searchmenu__listqqq {
  font-size: 17px;
  margin-bottom: 0;
  color: #000;
}
.searchmenu__listqqq a {
  margin: 10px 0 10px 0 !important;
  line-height: normal!important;
  font-size: 17px!important;
}
.searchmenu__listqqq a:hover {
  color: #ff5722;
}
.searchmenu__listqqq a:hover span{
  color: #000000;
}
.searchmenu__listqqq span {
  color: #ff5722;
}
.searchmenu__listing__item h1.sub__headingsss {
  margin-bottom: 17px;
  font-size: 27px;
  border-bottom: 1px solid #000000;
  padding-left: 0px;
  overflow: hidden;
}
.searchmenu__listing__item2 {
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6fa;
  display: flex;
}
.searchmenu__listing__item2 a{
  display: flex!important;
  align-items: center;
  margin: 0 0 7px 0!important;
}
.searchmenu__listing__item img {
  width: auto;
  height: 60px;
  margin-bottom: 0;
  margin-right: 17px;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
}
.searchmenu__listing__item h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 7px;
  max-height: 57px;
  overflow: hidden;
  line-height: 27px;
}
.searchmenu__listing__item2 h5 {
  font-size: 16px;
  font-weight: 400;
  color: #484a48;
}
.header__middle__search {
 cursor: pointer;
}
.header__middle__search svg.MuiSvgIcon-root {
  vertical-align: middle;
}
.searchmenu__listing__item2sss{margin-top: 37px;}
.audio__section3 img {
  margin: 17px auto 17px;
  border-radius: 7px;
  background: #f5f6f9;
  object-fit: cover;
  object-position: center top;
  width: auto;
  height: 217px;
  max-width: 100%;
  display: flex;
}
.audio__section3 h1 {
  font-size: 17px;
  line-height: 27px;
  font-weight: 600;
  text-align: center;
  color: #064532;
  margin-top: 27px;
  margin-bottom: 27px;
}
.audio__section3 h6 {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #f1592b;
}
.audioinner__desccc {
  font-size: 15px!important;
  color: #575957!important;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--font-family)!important;
  text-align: center;
}
.audio__section3 {
  border: 1px solid #e6e6e6;
  margin-right: 17px;
  padding: 17px;
  margin-bottom: 17px;
  margin-top: 17px;
  border-radius: 10px;
}
.audio__section3:hover {
  border: 1px solid #f1592b;
}
.Video__innerpage__inner {
  position: relative;
  width: 100%;
}
.Video__innerpage__inner iframe {
  width: 93%;
  height: 290px;
  box-shadow: 2px 2px 10px 3px rgb(196 228 254);
  border: 4px solid #fff;
  border-radius: 17px;
  margin-top: 17px;
}
.Video__innerpage__inner h1 {
  font-size: 20px;
  margin-top: 17px;
  margin-bottom: 27px;
  text-align: center;
}
.publisher__section3 {
  border: 1px solid #e6e6e6;
  margin-right: 17px;
  padding: 17px;
  margin-bottom: 17px;
  margin-top: 17px;
  border-radius: 10px;
}
.publisher__section3:hover {
  border: 1px solid #f1592b;
}
.publisher__section3 h1 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  text-align: center;
  color: #064532;
}
.publisher__section3 h6 {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #f1592b;
}
.publisherinner__desccc {
  font-size: 15px!important;
  color: #575957!important;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--font-family)!important;
  text-align: center;
}
.publisher__section3 img {
  margin: 17px auto 17px;
  border-radius: 7px;
  background: #f5f6f9;
  object-fit: contain;
  object-position: center top;
  width: auto;
  height: 147px;
  max-width: 100%;
  display: flex;
}
.notificationsss {
  position: absolute;
  width: 45%;
  right: 47px;
  top: 17px;
  z-index: 999;
}
li.menu-item.navqqq.header__middle__noticasss {
  margin: 0px 7px 0px 7px;
  cursor: pointer;
  position: relative;
}
.header__middle__noticasss svg.MuiSvgIcon-root {
  vertical-align: middle;
}
.notificationsss {
  right: 0px;
  text-align: left;
  position: absolute;
  top: 100%;
  display: block;
  margin: 7px 0 12px;
  background-color: #fff;
  color: #000000;
  border: 1px solid #dcdedf;
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  width: 437px;
  padding: 17px;
  z-index: 999;
}
.notifications__item2{display: flex;margin-bottom: 10px;
  border-bottom: 1px solid #e6e6fa;}
.notifications__item2 img {
  height: 77px;
  object-fit: cover;
  border-radius: 7px;
  margin-right: 17px;
}
.notifications__item2 a {
  display: flex!important;
  align-items: center;
  margin: 0 0 7px 0!important;
}
.notifications__item2 h2 {
  font-size: 18px;
  font-weight: 500;
}
.notifications__item2 h4 {
  font-size: 17px;
  font-weight: 400;
  display: flex;
  color: #f1592b;
}
.bottoms__innerpage {
  display: flex;
}
.bottoms__innerpage img {
  height: 327px;
  margin: 0 auto;
  display: block;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  border-radius: 7px;
  margin-right: 37px;
}
.bottoms__qqq {
  display: flex;
  justify-content: space-between;
  margin-bottom: 37px;
  border-bottom: 1px solid lavender;
  padding-bottom: 33px;
}
.bottoms__innerpagelist .bottoms__qqq:last-child {
  margin-bottom: 0px;
  border-bottom: 0px solid lavender;
}
.bottoms__section {
  position: relative;
  padding: 60px 0 37px 0;
}
.bottoms__section h1 {
  font-size: 27px;
  line-height: 37px;
  font-weight: 700;
  text-align: left;
  color: #064532;
}
.bottoms__innerauthorsss {
  font-size: 16px;
  text-align: left;
  margin-bottom: 23px;
  margin-top: 0;
  color: #5b5857;
}
.bottoms__section h6 {
  font-size: 20px;
  margin-bottom: 27px;
  color: #ff1100;
}
.bottoms__desccc, .bottoms__desccc p, .bottoms__desccc p span, .bottoms__desccc p strong, .bottoms__desccc p span strong {
  font-size: 17px!important;
  color: #575957!important;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--font-family)!important;
}
.footersss {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer p {
  text-align: center;
  font-size: 17px;
}
.footersss p {
  line-height: 32px;
  font-size: 17px;
  max-width: 70%;
  color: #716b6b;
  margin-bottom: 27px;
}
.footersss input:focus {
  box-shadow: none;
  background: none;
  border-color: #7329c2!important;
  color: #495057;
}
.footersss input.form-control {
  border-radius: 5px 0px 0px 5px;
  padding: 14px 20px 14px 20px;
  position: relative;
  border: 1px solid lavender;
  width: 460px;
}
.fotter__btnsss {
  border-radius: 0px 5px 5px 0px;
  padding: 14px 26px 14px 27px;
  margin: 0px 0px 0px 0px;
  color: #FFFFFF;
  background-color: #f4744e;
  border: 0;
  background-image: linear-gradient(to bottom right, #f1bf64 0%, #f71d36 100%) !important;
  font-size: 18px;
}
.fotter__btnsss svg.MuiSvgIcon-root {
  vertical-align: middle;
}
.forms__nesss{
  margin-bottom: 37px;
}
p.success__footersss {
  color: #0db70d;
  font-size: 20px;
  font-weight: 600;
}
p.errors__footersss {
  color: #ff1100;
  font-size: 20px;
  font-weight: 600;
}
.boozzz{
  padding: 60px;
  box-shadow: 0px 1px 40px 0px rgb(40 63 116 / 10%);
}
.boozzz2 {
  margin-left: 27px;
  background-color: #ffffff;
  background-image: linear-gradient(to bottom right, #ffffff 0%, #ffffff 100%) !important;
  border-radius: 7px;
  padding: 37px;
  box-shadow: 0px 1px 40px 0px rgb(40 63 116 / 10%);
  border-top: 1px solid #f46d4d;
}
.adminsss input.form-control {
  border-radius: 5px 0px 0px 5px;
  padding: 14px 20px 14px 20px;
  position: relative;
  border: 1px solid lavender;
  width: 177px;
}
.profileimagesss {
  background-color: #f71d36;
  position: relative;
  height: 107px;
  width: 107px;
  border-radius: 50%;
  float: left;
  display: block;
  background-image: linear-gradient(to bottom right, #f1bf64 0%, #f71d36 100%) !important;
}
span.lognosss__spans2sss {
  font-size: 67px;
  text-transform: capitalize;
  vertical-align: middle;
  position: absolute;
  left: 0px;
  right: 0;
  text-align: center;
  top: 7%;
  font-family: var(--font-family);
  color: white;
  font-weight: 400;
}
.card__admin .profileimagesss{
  background: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%) !important;
  height: 127px;
  width: 127px;
  margin-right: 27px;
}

.imprss__desccc, .imprss__desccc p, .imprss__desccc p span,.imprss__desccc p strong {
  font-size: 18px!important;
  font-size: 18px;
  line-height: 37px;
  font-family: var(--font-family)!important;
  background-color: rgb(255 255 255 / 0%)!important;
  color: rgb(255 255 255)!important;
}
.imprss__desccc p br {display: none;}
.card__admin span.lognosss__spans2sss {
  font-size: 77px;
  text-transform: capitalize;
  vertical-align: middle;
  position: absolute;
  left: 0px;
  right: 0;
  text-align: center;
  top: 10%;
  font-family: var(--font-family);
  color: white;
  font-weight: 400;
}

.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}
.divLoaderwww {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.footer img{
  margin-bottom: 7px;
}
button.pagination__buttonsss {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: #f71d36;
  background-image: linear-gradient(to bottom right, #f1bf64 0%, #f71d36 100%) !important;
  padding: 7px 27px;
  font-size: 17px;
  color: white;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  border: 0;
  display: flex;
  float: right;
}
.loading {
  display: block;
  width:100%;
}
.loading svg {
  float: right;
}
.video__section__preview {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px 0px 70px 0px;
  overflow: hidden;
}
.video__section__preview .Video__innerpage__inner iframe {
  width: 100%;
  height: 817px;
  box-shadow: 2px 2px 10px 3px rgb(196 228 254);
  border: 4px solid #fff;
  border-radius: 17px;
  margin-top: 17px;
}
.viodosss__innerpage .MuiButton-containedPrimary {
  background-color: #f71d36;
  background-image: linear-gradient(to bottom right, #f1bf64 0%, #f71d36 100%) !important;
  margin: 17px auto;
  display: flex;
  padding: 7px 27px 7px 27px;
}
.allsss__desc, .allsss__desc p, .allsss__desc p span, .allsss__desc p strong, .allsss__desc p span strong {
  font-size: 17px!important;
  color: #575957!important;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--font-family)!important;
  background-color: transparent!important;
}
.allsss__desc p br, .audioinner__desccc p br{
  display: none;
}
.allsss__section2 h1 {
  height: 57px;
  overflow: hidden;
}
.allsss__desc{
  height: 217px;
  overflow: hidden;
}
.audioinner__desccc, .audioinner__desccc p, .audioinner__desccc p span, .audioinner__desccc p strong, .audioinner__desccc p span strong {
  font-size: 17px!important;
  color: #575957!important;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--font-family)!important;
  background-color: transparent!important;
}
.bookpres__linsss {
  word-break: break-word;
}

@media(max-width:367px){
  .tabless__listsss .WithStyles\(ForwardRef\(TableCell\)\)-body-16 {padding: 7px;}
}

@media(max-width:767px){
  .BestDeals__headingsss__resss{font-family: var(--font-family);font-size: 30px;font-weight: 500;margin-bottom: 10px;text-align: center;color: #000000;text-shadow: 0px 2px 2px #a32806;}
  .books__section2 img { margin-right: auto;display: flex;}
  .books__section4 {display: none;}
  .book__prewsss {flex-direction: column;}
  .boozzz2 {margin-left: auto; padding: 17px;display: flex;flex-direction: column;flex-wrap: wrap;align-items: center;}
  .boozzz {padding: 10px;}
  .books__section__preview img { max-width: 277px;}
  .Video__innerpage__inner iframe {width: 100%;height: 227px;}
  .book__preview__title {font-size: 17px;line-height: 32px;}
  .bookpres__linsss li {word-break: break-all;}
  .header__middle__logos {display: flex;align-items: center;flex-direction: column;}
  .header__middle__logos img{max-width: 187px;;}
  .header__middle__menusss {display: flex;align-items: center;flex-direction: column;}
  .main-nav .menubar__button {color: #f15c2f!important;margin: 0px auto 0!important;}
  .fullsectionsss {display: none;}
  .Impressive__headingsss {font-size: 30px;margin-bottom: 17px;}
  .text__inner__four {display: flex;flex-direction: column;align-items: center;justify-content: space-between;}
  img.seller__img { height: 93px;width: 93px;max-width: 92px;}
  .text__inner__two h3 {font-size: 20px;}
  .text__inner__three p {margin-bottom: 0;font-size: 14px!important;}
  .Impressive__innerpage .MuiButton-containedPrimary { margin-bottom: 27px;}
  .BestDeals__firsss {flex-direction: column;align-items: center;}
  .BestDeals__innerpagelist {flex-direction: column;}
  .BestDeals__headingsss{display: none;}
  .Featured__innerpage {flex-direction: column;align-content: space-between;align-items: center;justify-content: space-between;}
  .Featured__innerpage img {margin-bottom: 37px;}
  .Featured__headingsss {font-size: 30px;}
  .Standard__headingsss {display: none;}
  .Featured__innertext {display: flex;flex-direction: column;align-items: center;margin-left: 0;}
  .Featured__innerpage h1 {text-align: center;}
  .feaaa__desccc, .feaaa__desccc p, .feaaa__desccc p span, .feaaa__desccc p strong {text-align: center;}
  .Category__section{display: none;}
  .Standard__innerpagelist {justify-content: space-between;flex-direction: column;align-items: center;}
  .Audio__innerpagesss {flex-direction: column;}
  .Video__innerpage2 {padding: 17px;}
  .Video__innerpage2 iframe {height: 227px;border: 3px solid white;}
  .Video__sec .Video__innerpage2 h1 {margin-top: 17px;}
  .Video__innerpage iframe {height: 237px;outline-offset: 0px;border-radius: 17px;margin-bottom: 17px;}
  .publisher__section {padding: 7px 0px 60px 0px;}
  .publisher__headingsss {font-size: 30px;margin-bottom: 10px;}
  .publisher__innerpagesss {flex-direction: column;}
  .bottoms__section {padding: 37px 0 37px 0;}
  .bottoms__innerpage {flex-direction: column;align-items: center;justify-content: space-between;align-content: space-between;}
  .bottoms__innertext { display: flex;flex-direction: column;align-items: center;margin: 27px 0 0 0;}
  .bottoms__section h1 {font-size: 20px;text-align: center;}
  .bottoms__desccc, .bottoms__desccc p, .bottoms__desccc p span, .bottoms__desccc p strong, .bottoms__desccc p span strong {text-align: center;}
  .footersss input.form-control {width: 100%;margin-bottom: 17px;}
  .footersss p {max-width: 100%;}
  .Featured__section {padding: 17px 0px 37px 0px;}
  .Audio__headingsss {font-size: 30px;}
  .Video__section {margin: 27px 0px 37px 0px;}
  .publisher__innerpagesss img {height: 137px;}
  .Video__headingsss {font-size: 30px;}
  .Impressive__section {padding: 37px 0;}
  li.menu-item.navqqq.header__middle__search {display: none;}
  li.menu-item.navqqq.header__middle__noticasss {display: none;}
  .menusmrsss {padding: 0;}
  .main-nav .menubar__button:hover {background-color: #f1592b!important;color: white!important;}
  .main-nav .menubar__button svg {vertical-align: middle;}
  .menus__left {padding: 0;}
  .BestDeals__qqq:first-child img {max-height: 407px;height: 407px;}
  .Standard__innerpagelist .Standard__list2 img {max-height: 407px;height: 407px;}
  .Impressive__cover {max-height: 407px;height: 407px;}
  .text__inner__four2 {margin-left: 0; display: flex;flex-direction: column;align-items: center;}
  .imprss__desccc, .imprss__desccc p, .imprss__desccc p span, .imprss__desccc p strong {text-align: center;}
  .BestDeals__innerpagesss {display: flex;justify-content: space-between;flex-direction: column;}
  .BestDeals__section:after { border-radius: 0 0 7% 7%;}
  .Featured__innerpagelist {align-items: center;flex-direction: column;}
  .BestDeals__section {padding: 70px 0px 20px 0px;}
  .Standard__innerpagesss {flex-direction: column;}
  .bottoms__innerpage img {margin: initial;}
  .forms__nesss { display: flex;flex-direction: column;}
  .Loginsec {min-width: auto!important;padding: 27px 17px!important;}
  .sublogin__headingsss {font-size: 22px!important;}
  .gr__button { padding: 10px 14px!important;}
  .video__section__preview .Video__innerpage__inner iframe {height: 260px;}
}









@media(min-width:768px) {
.BestDeals__headingsss__resss{display: none;}
}
@media(min-width:768px) and (max-width:991px){
  .books__section__preview img {max-width: 210px!important;max-height: 300px!important;}
  .book__prewsss {align-items: flex-start;}
  .video__section__preview .Video__innerpage__inner iframe {height: 377px;}
  .books__section2 {padding: 7px;align-items: center;flex-direction: column;flex-wrap: wrap;align-content: center;word-break: break-word;}
  .Video__innerpage__inner iframe {width: 97%;height: 260px;}
  .allsss__desc {height: 167px;}
  .audio__section3 { padding: 7px;}
  .publisher__section3 {padding: 10px;}
  .books__section2 p {margin-top: 0;}
  h1.sub__headingsss2 {font-size: 24px;}
  .books__section4 {padding: 4px;}
  .header__middle__logos {display: flex;align-items: center;flex-direction: column;}
  .header__middle__logos img{max-width: 187px;;}
  .header__middle__menusss {display: flex;align-items: center;flex-direction: column;}
  .main-nav .menubar__button {color: #f15c2f!important;margin: 0px auto 0!important;}
  .fullsectionsss .itemsss img {height: 247px;width: 160px;}
  .fullsectionsss .itemsss {max-width: 160px;}
  .itemsss2 {top: 7%;left: 0%;font-size: 47px!important;}
  .BestDeals__innerpage {padding: 7px;}
  .BestDeals__qqq:first-child img { max-height: 277px;height: 277px;}
  .BestDeals__innerpagelist img {max-height: 160px;height: 160px;} 
  .Featured__innerpage {padding: 10px;flex-wrap: wrap;flex-direction: column;align-items: center;}
  .Featured__innertext {margin-left: 0;display: flex;align-items: center;flex-direction: column;margin-top: 27px;}
  .feaaa__desccc, .feaaa__desccc p, .feaaa__desccc p span, .feaaa__desccc p strong { text-align: center;}
  .Category__section {display: none;}
  .Standard__innerpagelist .Standard__list2 img { max-height: 247px;height: 247px;}
  .Standard__innerpageqqq { padding: 10px;}
  .Standard__innerpage {padding: 10px;}
  .Standard__desc, .Standard__desc p, .Standard__desc p span, .Standard__desc p strong {font-size: 14px!important;}
  .Standard__innerpagelist img { max-height: 147px; height: 147px;}
  .Audio__innerpage {padding: 17px;}
  .Audio__innerpagesss img {max-height: 147px;}
  .Video__innerpage2 {padding: 17px;}
  .Video__innerpage2 iframe {height: 317px;}
  .Video__innerpage iframe {height: 160px;outline: 1px solid #5b5d69; outline-offset: 10px;}
  .publisher__innerpagesss img {height: 77px;object-fit: cover;object-position: center;}
  .publisher__innerpage {padding: 17px;}
  .publisher__desc, .publisher__desc p, .publisher__desc p span, .publisher__desc p strong, .publisher__desc p span strong { text-align: center;}
  .Impressive__cover { max-height: 287px;height: 287px;}
  img.seller__img {height: 70px;width: 70px;max-width: 70px;}
  .Impressive__innerpagetwo img {max-height: 137px;height: 137px;}
  .text__inner__two h3 {font-size: 18px;}
  .text__inner__three {margin-left: 17px;}
  .Impressive__innerpage .text__inner h1 {font-size: 24px;line-height: 37px;}
  .Impressive__innerpagelist {flex-wrap: wrap;align-items: flex-start;align-content: flex-start;justify-content: flex-end;}
  .imprss__desccc, .imprss__desccc p, .imprss__desccc p span, .imprss__desccc p strong {font-size: 17px!important;line-height: 27px!important;}
  .BestDeals__headingsss {font-size: 30px;}
  .Featured__headingsss {font-size: 30px;}
  .Standard__headingsss {font-size: 30px;}
  .Audio__headingsss {font-size: 30px;}
  .Video__headingsss {font-size: 30px;}
  .publisher__headingsss {font-size: 30px;}
  .Impressive__headingsss {font-size: 30px;}
  .itemsss__two h2 {height: 67px;overflow: hidden;}
  .itemsss:hover .itemsss__two h2 {height:auto;}
  li.menu-item.navqqq.header__middle__search {display: none;}
  li.menu-item.navqqq.header__middle__noticasss {display: none;}


}


@media(min-width:992px) and (max-width:1199px){
  .header__middle .menu-item a {font-size: 14px!important;margin: 10px 7px 10px 0px!important;}
  .header__middle .header__middle__logos img{max-width: 170px;}
  .BestDeals__qqq:first-child img {max-height: 377px; height: 377px;}
  .BestDeals__innerpagelist img { max-height: 207px;height: 207px;}
  .Featured__innerpage img {max-height: 147px;height: 147px;}
  .Standard__innerpagelist .Standard__list2 img { max-height: 397px;height: 397px;}
  .Standard__innerpagelist img {max-height: 197px;height: 197px;}
  .Standard__desc, .Standard__desc p, .Standard__desc p span, .Standard__desc p strong {font-size: 15px!important; line-height: 27px;}
  .Audio__innerpagesss img {max-height: 177px;}
  .publisher__innerpagesss img {height: 137px;max-width: 147px;}
  .Video__innerpage2 {padding: 17px;}
  .Video__innerpage iframe {outline-offset: 13px;}
  .books__section2 img {height: 207px;}
  .books__section__preview img {max-width: 267px;}
  .authors__section__list {padding: 22px 22px 22px 22px;margin-right: 6px;margin-left: 6px;margin-bottom: 17px;}
  .card__admin .profileimagesss {height: 97px;width: 97px;}
  .card__admin span.lognosss__spans2sss {font-size: 57px;}
  .imgsss__tablesss {object-fit: contain!important;width: 177px!important;}
  .card__admin {padding: 17px!important;}
  .card__admin2s {padding: 17px!important;}
  .MuiButton-containedSizeLarge {padding: 8px 12px!important;}
  .menu-item a { font-size: 16px!important;margin: 10px 7px 10px 0px!important;}
  .plan__discount{display: none;}

}


@media(min-width:1200px) and (max-width:1360px){
  .header__middle .header__middle__logos img{max-width: 170px;}
  .imgsss__tablesss {object-fit: contain!important;width: 177px!important;}
  .card__admin {padding: 17px!important;}
  .card__admin2s {padding: 17px!important;}
  .MuiButton-containedSizeLarge {padding: 8px 12px!important;}
}

.paternsss h2{
  font-size: 27px;
  margin-bottom: 27px;
}
.paternsss h3{
  font-size: 24px;
  margin-top: 27px;
  margin-bottom: 10px;
}
.paternsss p{
  line-height: 34px;
}
.paternsss a{
  color:#0676cf;
}